import useSWRImmutable from "swr/immutable";

import { fetchWeatherData } from "./apis";

export const useWeather = () => {
  const { data } = useSWRImmutable("weather", fetchWeatherData, {
    refreshInterval: 1000 * 30,
    revalidateOnMount: true,
    suspense: true,
  });

  return { data };
};
