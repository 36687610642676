import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const Temperature = styled.div`
  flex: 1;

  color: #ffffff;
  font-weight: 400;
  font-size: 12px;
`;
