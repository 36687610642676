import styled from "@emotion/styled";

import WarningRainBlackSvg from "./warning-rain-black.svg?url";

export type IconProps = {
  $color?: string;
  $content: string;
};

export const Icon = styled.div<IconProps>`
  inline-size: 18px;
  block-size: 18px;

  transform: translateY(3px);

  &:before {
    content: "${props => props.$content}";

    display: flex;
    justify-content: center;
    align-items: center;

    inline-size: 100%;
    block-size: 100%;

    color: ${props => props.$color ?? "#ffffff"};
    font-size: 30px;
    font-family: "scmp-weather-icons";
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export const WarningRainBlackIcon = styled.div`
  inline-size: 24.27px;
  block-size: 18px;

  background-image: url(${WarningRainBlackSvg});
  background-size: 24.27px 18px;
`;
