import { NoSsr } from "@mui/base";
import type { FunctionComponent } from "react";

import { DateWeather } from "scmp-app/components/date-weather";
import { SCMPPlusTabs } from "scmp-app/components/scmp-plus-tabs";
import { useEdition, useEditionValue } from "scmp-app/lib/edition";
import { headerEditionOptions } from "scmp-app/lib/edition/data";

import {
  Container,
  DateWeatherAndBackToOldScmpButton,
  Edition,
  EditionList,
  Wrapper,
} from "./styles";

export type Props = {
  className?: string;
};

export const HomeEdition: FunctionComponent<Props> = ({ className }) => {
  const edition = useEditionValue();
  const { update: updateEdition } = useEdition();

  return (
    <Container className={className}>
      <Wrapper>
        <SCMPPlusTabs isPlus={false} />
        <EditionList>
          <NoSsr>
            {headerEditionOptions.map(option => (
              <Edition
                $isActive={edition === option.value}
                key={option.value}
                onClick={() => {
                  updateEdition(option.value);
                  window.location.href = option.href;
                }}
              >
                {option.label}
              </Edition>
            ))}
          </NoSsr>
        </EditionList>

        <DateWeatherAndBackToOldScmpButton>
          <DateWeather />
        </DateWeatherAndBackToOldScmpButton>
      </Wrapper>
    </Container>
  );
};

HomeEdition.displayName = "HomeEdition";
