import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRoboto, transientOptions } from "@product/scmp-sdk";

import { BaseLink } from "scmp-app/components/common/base-link";

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

type SCMPPlusTabsListProps = {
  $isActive: boolean;
  $isPlus: boolean;
};
export const SCMPPlusTabsList = styled(BaseLink, {
  ...transientOptions,
})<SCMPPlusTabsListProps>`
  padding-block: 8px;
  padding-inline: 12px;

  color: #ffffff;
  font-weight: 900;
  font-size: 14px;
  font-family: ${fontRoboto};
  text-transform: uppercase;

  border-radius: 4px 4px 0 0;

  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  & i {
    font-weight: 900;
    font-family: ${fontRoboto};
    font-style: italic;
  }
  ${props =>
    props.$isActive
      ? css`
          color: #001246;

          background-color: ${props.$isPlus ? "#7ca9ff" : "#ffca05"};

          &:hover {
            text-decoration: none;
          }
        `
      : null}
`;
