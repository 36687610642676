import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRoboto, transientOptions } from "@product/scmp-sdk";

import { setFullViewportWidthStyles } from "scmp-app/lib/styles";

export const Container = styled.div`
  ${setFullViewportWidthStyles()}
  display: none;

  block-size: 32px;

  border-block-end: 2px solid #ffca05;

  background-color: #001246;

  ${props => props.theme.breakpoints.up("tablet")} {
    display: block;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  block-size: 100%;
  max-inline-size: 1344px;
  margin-inline: auto;
  padding-inline: 32px;
`;

type EditionProps = {
  $isActive: boolean;
};
export const Edition = styled("div", { ...transientOptions })<EditionProps>`
  display: block;

  padding-inline: 6px;

  color: #ffffff;
  font-size: 12px;
  text-transform: uppercase;

  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  ${props =>
    props.$isActive
      ? css`
          color: #ffca05;
          font-weight: 700;
          &:hover {
            text-decoration: none;
          }
        `
      : null}
`;

export const EditionList = styled.div`
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;

  display: flex;
  align-items: center;

  transform: translate(-50%, -50%);

  ${Edition}:not(:last-child) {
    border-inline-end: 1px solid #ffffff;
  }
`;

export const DateWeatherAndBackToOldScmpButton = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const DateWeather = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const DisplayDate = styled.div`
  color: #ffffff;
  font-size: 12px;

  b {
    display: inline-block;

    margin-inline-end: 4px;
  }
`;

export const BackToOriginalScmpButton = styled.div`
  inline-size: fit-content;
  padding-block: 8px;
  padding-inline: 12px;

  color: #001246;
  font-weight: 700;
  font-size: 12px;
  font-family: ${fontRoboto};
  font-style: normal;
  line-height: normal;

  background-color: #ffca05;

  cursor: pointer;
`;

export const SCMPPlusTabs = styled.div`
  display: flex;
  align-items: center;
`;

type SCMPPlusTabsListProps = {
  $isActive: boolean;
};
export const SCMPPlusTabsList = styled("div", {
  ...transientOptions,
})<SCMPPlusTabsListProps>`
  padding-block: 8px;
  padding-inline: 12px;

  color: #ffffff;
  font-weight: 900;
  font-size: 14px;
  text-transform: uppercase;

  border-radius: 4px 4px 0 0;

  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  ${props =>
    props.$isActive
      ? css`
          color: #001246;

          background-color: #ffca05;

          &:hover {
            text-decoration: none;
          }
        `
      : null}
`;
