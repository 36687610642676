import styled from "@emotion/styled";

import LogoIcon from "./icon-logo.svg";

export const StyledLogo = styled(LogoIcon)`
  inline-size: 58px;
  block-size: 17px;
  path {
    transition: fill 0.2s ease-in-out;
  }
`;

export const Container = styled.div`
  inline-size: 74px;
  block-size: 30px;
  padding-block: 7px 0;
  padding-inline: 7px;

  border: 1px solid #001246;
  border-radius: 2px;

  transition: border-color 0.2s ease-in-out;

  &:hover {
    border-color: #2957b5;

    ${StyledLogo} {
      path {
        fill: #2957b5;
      }
    }
  }
`;
