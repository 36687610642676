import type { FunctionComponent } from "react";

import { useWeather } from "./hooks";
import { HeaderWeatherIcon } from "./icon";
import { Container, Temperature } from "./styles";

type Props = {
  className?: string;
};

export const HeaderWeather: FunctionComponent<Props> = ({ className }) => {
  const { data } = useWeather();

  return (
    <Container className={className}>
      <Temperature>{data?.temperature?.temperature}</Temperature>
      {data?.temperature?.displayIcon && (
        <HeaderWeatherIcon
          name={data?.temperature?.displayIcon}
          title={data?.temperature?.description}
        />
      )}
      {data?.warning?.map(warning => (
        <HeaderWeatherIcon key={warning?.name} name={warning?.name} title={warning?.description} />
      ))}
    </Container>
  );
};

HeaderWeather.displayName = "HeaderWeather";
